<template>
  <div
    class="modal fade"
    id="declareLostDocument"
    aria-hidden="true"
    tabindex="-1"
    ref="declareLostDocument"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0">
          <h5 class="modal-title">Declare a Lost Document</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            ref="closeButton"
          ></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitLostDoc">
            <h5 class="form-heading mb-3">
              Fill the form below to declare lost document
            </h5>
            <div class="row g-3 mb-3">
              <div class="col-12 col-md-6">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Surname"
                  aria-label="Surname"
                  required
                  v-model="lostObj.surname"
                />
              </div>
              <div class="col-12 col-md-6">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Given Names"
                  aria-label="Given Name"
                  required
                  v-model="lostObj.given_name"
                />
              </div>
            </div>
            <div class="row g-3 mb-3">
              <div class="col-12 col-md-6">
                <input
                  type="date"
                  class="form-control"
                  placeholder="Date of birth"
                  aria-label="Date of birth"
                  required
                  v-model="lostObj.dob"
                />
              </div>
              <div class="col-12 col-md-6">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Profession"
                  aria-label="Profession"
                  required
                  v-model="lostObj.profession"
                />
              </div>
            </div>
            <div class="row g-3 mb-3">
              <div class="col-12 col-md-6">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Your email"
                  aria-label="Your email"
                  required
                  v-model="lostObj.email"
                />
              </div>
              <div class="col-12 col-md-6">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Phone number"
                  aria-label="Phone number"
                  required
                  v-model="lostObj.phone_number"
                />
              </div>
            </div>
            <div class="row g-3 mb-3">
              <div class="col-12 col-md-6">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Country"
                  aria-label="Country"
                  required
                  v-model="lostObj.country"
                />
              </div>
              <div class="col-12 col-md-6">
                <input
                  type="text"
                  class="form-control"
                  placeholder="City"
                  aria-label="City"
                  v-model="lostObj.city"
                />
              </div>
            </div>
            <div class="row px-2 mb-3">
              <textarea
                class="form-control overflow-auto"
                v-model="lostObj.description"
                placeholder="Leave a Note"
                cols="5"
                rows="2"
                maxlength="1024"
                @input="resize"
                ref="lostDocumentDescription"
              ></textarea>
            </div>

            <div class="modal-footer border-0 justify-content-center py-3">
              <button
                type="button"
                class="btn cancel-btn px-5 py-2"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="submit"
                class="btn submit-btn px-5 py-2 ms-1 ms-lg-3"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex"

export default {
  name: "DeclareLostDocument",
  props: {
    lostDoc: Object,
    method: {
      type: String,
      default: "create"
    },
    user: Object
  },
  data() {
    return {
      lostObj: null
    }
  },
  created() {
    this.lostObj = this.lostDoc
    this.addUserData()
  },
  mounted() {
    let modal = this.$refs.declareLostDocument
    modal.addEventListener("hidden.bs.modal", () => {
      this.closeModal()
    })
    modal.addEventListener("shown.bs.modal", () => {
      let textarea = this.$refs.lostDocumentDescription
      textarea.style.height = `${textarea.scrollHeight}px`
    })
  },
  methods: {
    ...mapActions("lostDocument", ["createLostDocument", "editLostDocument"]),
    closeModal() {
      this.$emit("reset-default")
    },

    submitLostDoc() {
      this.lostObj.status = "lost"
      this.lostObj.doc_type = "Identity Card"
      let formData = new FormData()
      for (const property in this.lostObj) {
        formData.append(property, this.lostObj[property])
      }

      if (this.method == "create") {
        this.createLostDocument(formData)
        this.$refs.closeButton.click()
      } else {
        formData.append("id", this.lostObj.id)
        this.editLostDocument(formData)
        this.$refs.closeButton.click()
      }
    },
    clearImg() {
      this.imageUrl = null
      this.lostObj.image_path = null
      this.$refs.image.value = ""
    },
    resize(event) {
      event.target.style.height = "auto"
      event.target.style.height = `${event.target.scrollHeight}px`
    },
    addUserData() {
      let userData = {
        email: this.user.email,
        phone_number: this.user.phone_number,
        country: this.user.country,
        city: this.user.city
      }
      this.lostObj = { ...this.lostObj, ...userData }
    }
  },
  watch: {
    lostDoc(data) {
      this.lostObj = data
      this.addUserData()
    }
  }
}
</script>

<style scoped>
.form-control {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 28px;
  color: #6d6d6d;
}
.form-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;

  text-align: center;

  color: #000000;
}
.image-previewBlock {
  display: block;
  cursor: pointer;
  /* width: 300px; */
  height: 280px;
  background-position: center center;
  background-size: cover;
}
.cancel-btn {
  border: 1px solid #979797;
  box-sizing: border-box;
  border-radius: 52px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  color: #000000;
}
.submit-btn {
  background: linear-gradient(180deg, #ff411f 0%, #ff0bb9 100%);
  border-radius: 52px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  color: #ffffff;
}

.close-file-btn {
  top: 30px;
  right: 9px;
  cursor: pointer;
  color: #ffc107;
}

.close-file-btn:hover {
  color: #adb5bd;
}
</style>
