<template>
  <div class="container py-6" v-if="user">
    <!-- post missing documents -->
    <div class="row justify-content-center">
      <div class="col-lg-6 col-md-12 rounded-3 bg-white p-3 shadow-sm">
        <div class="d-flex align-items-center">
          <div
            class="d-flex align-content-center align-items-center px-2 py-2 pencil-icon"
          >
            <svg
              style="width: 24px; height: 24px; color: #ff411f"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="m14.06 9 .94.94L5.92 19H5v-.92L14.06 9m3.6-6c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.04 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29m-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75Z"
              />
            </svg>
          </div>
          <span class="mx-1 create-post-text">Create Post</span>
        </div>
        <!-- create post box -->
        <div
          class="d-flex align-items-center px-2 mt-3 py-1 rounded-pill"
          style="border: 1px solid #ff411f"
        >
          <profile-image
            :user="user"
            :size="40"
            :is-link="false"
            class="me-3"
          ></profile-image>
          <button
            type="button"
            class="btn btn-compose ms-2 rounded-pill w-100 text-left"
            style="text-align: left"
            data-bs-toggle="modal"
            href="#selectActionModal"
            role="button"
          >
            Post a Lost Document, {{ user.first_name }} ?
          </button>
        </div>
        <!-- Select action Modal -->
        <div
          class="modal fade"
          id="selectActionModal"
          aria-hidden="true"
          aria-labelledby="selectActionModalLabel"
          tabindex="-1"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header border-0">
                <h5 class="modal-title" id="selectActionModalLabel">
                  Select Action
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">Select your preferred Action</div>
              <div class="modal-footer d-flex justify-content-center">
                <button
                  class="btn btn-primary rounded-pill px-4"
                  data-bs-target="#declareLostDocument"
                  data-bs-toggle="modal"
                  data-bs-dismiss="modal"
                >
                  Declare Lost Document
                </button>
                <button
                  class="btn btn-primary rounded-pill px-4"
                  data-bs-target="#postLostDocument"
                  data-bs-toggle="modal"
                  data-bs-dismiss="modal"
                >
                  Post Lost Document
                </button>
              </div>
            </div>
          </div>
        </div>
        <post-lost-id
          :lostDoc="editedLostDoc"
          :method="lostDocMethod"
          :user="user"
          @reset-default="closeLostDocModal"
        ></post-lost-id>
        <declare-lost-document
          :lostDoc="editedLostDoc"
          :user="user"
          @reset-default="closeLostDocModal"
        ></declare-lost-document>
      </div>
    </div>
    <!-- browse missing documents  -->
    <div class="row justify-content-center mt-5">
      <div class="col-lg-6 col-md-12">
        <document-news-feed :user="user"></document-news-feed>
      </div>
    </div>
  </div>
</template>

<script>
import DeclareLostDocument from "@/components/lostDocument/DeclareLostDocument.vue"
import PostLostId from "@/components/lostDocument/PostLostId.vue"
import DocumentNewsFeed from "@/components/lostDocument/DocumentNewsFeed.vue"
import ProfileImage from "@/components/profile/ProfileImage.vue"
import { mapState } from "vuex"
export default {
  components: {
    DeclareLostDocument,
    PostLostId,
    DocumentNewsFeed,
    ProfileImage
  },
  data() {
    let editedLostDoc = {
      status: "",
      doc_type: "",
      image_path: "",
      surname: "",
      given_name: "",
      dob: "",
      profession: "",
      description: "",
      unique_identification_number: "",
      place_of_pick: ""
    }
    return {
      editedLostDoc,
      defaultLostDoc: Object.freeze(Object.assign({}, editedLostDoc)),
      lostDocMethod: "create"
    }
  },
  async mounted() {
    await this.$store.dispatch("user/getCurrentUser")
  },
  computed: {
    ...mapState("user", ["user"])
  },
  methods: {
    closeLostDocModal() {
      this.editedLostDoc = Object.assign({}, this.defaultLostDoc)
    }
  }
}
</script>

<style scoped>
.create-post-text {
  color: rgb(189, 188, 188);
  font-size: 0.8rem;
}

.pencil-icon {
  background-color: rgba(133, 122, 228, 0.24);
  border-radius: 100px;
  /* width: 2.5rem; */
}
/* create post box */
.create-post-box {
  border: 1px solid rgb(238, 238, 238);
  border-radius: 15px;
}
.modal-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 28px;

  color: #6d6d6d;
}
/* browse news feed  */

.btn-compose {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #cdcdcd;
}
</style>
