<template>
  <div
    class="modal fade"
    id="postLostDocument"
    aria-hidden="true"
    tabindex="-1"
    ref="postLostDocument"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0">
          <h5 class="modal-title">Post a Lost Document</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            ref="closeButton"
          ></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitLostDoc">
            <h5 class="form-heading mb-3">
              Fill the form below to post a lost document
            </h5>

            <h5 class="form-heading mb-3">Card Details</h5>
            <div class="row g-3 mb-3">
              <div class="col-12 col-md-6">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Surname"
                  aria-label="Surname"
                  required
                  v-model="lostObj.surname"
                />
              </div>
              <div class="col-12 col-md-6">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Given Names"
                  aria-label="Given Name"
                  required
                  v-model="lostObj.given_name"
                />
              </div>
            </div>
            <div class="row g-3 mb-3">
              <div class="col-12 col-md-6">
                <input
                  type="date"
                  class="form-control"
                  placeholder="Date of birth"
                  aria-label="Date of birth"
                  required
                  v-model="lostObj.dob"
                />
              </div>
              <div class="col-12 col-md-6">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Profession"
                  aria-label="Profession"
                  required
                  v-model="lostObj.profession"
                />
              </div>
            </div>
            <div class="row g-3 mb-3">
              <div class="col-12 col-md-6">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Unique Identification Number"
                  aria-label="Unique Identification Number"
                  required
                  v-model="lostObj.unique_identification_number"
                />
              </div>
              <div class="col-12 col-md-6">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Place of pick"
                  aria-label="Place of pick"
                  v-model="lostObj.place_of_pick"
                />
              </div>
            </div>
            <h5 class="form-heading mb-3">Your Information</h5>
            <div class="row g-3 mb-3">
              <div class="col-12 col-md-6">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Your email"
                  aria-label="Your email"
                  required
                  v-model="lostObj.email"
                />
              </div>
              <div class="col-12 col-md-6">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Your Phone Number"
                  aria-label="Your Phone number"
                  required
                  v-model="lostObj.phone_number"
                />
              </div>
            </div>
            <div class="row g-3 mb-3">
              <div class="col-12 col-md-6">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Your Country"
                  aria-label="Country"
                  required
                  v-model="lostObj.country"
                />
              </div>
              <div class="col-12 col-md-6">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Your City"
                  aria-label="City"
                  required
                  v-model="lostObj.city"
                />
              </div>
            </div>
            <div class="row px-2 mb-3">
              <textarea
                class="form-control overflow-auto"
                v-model="lostObj.description"
                placeholder="Leave a Note"
                cols="5"
                rows="2"
                maxlength="1024"
                @input="resize"
                ref="lostDocumentDescription"
              ></textarea>
            </div>
            <div class="row px-2 mb-3">
              <div style="position: relative" v-if="lostObj.image_path">
                <i
                  class="bi bi-x-circle-fill fs-4 rounded-circle px-1 close-file-btn"
                  style="position: absolute"
                  @click="clearImg"
                ></i>
                <img
                  v-if="imageUrl"
                  :src="imageUrl"
                  class="w-100 my-4"
                  alt="post-lost-image"
                />
                <cld-image
                  :public-id="getCloudinaryPId(lostObj.image_path)"
                  loading="lazy"
                  dpr="auto"
                  responsive="width"
                  width="auto"
                  crop="scale"
                  class="rounded-3 mt-2"
                  v-else
                >
                  <cld-transformation quality="auto:eco" fetchFormat="auto" />
                </cld-image>
              </div>

              <div>
                <input
                  type="file"
                  v-show="false"
                  id="image"
                  ref="image"
                  accept="image/*"
                  @change="handleFile"
                />
                <button
                  type="button"
                  class="btn btn-outline-primary d-flex align-items-center justify-content-center rounded-pill px-5 w-100"
                  @click="$refs.image.click()"
                >
                  <i class="bi bi-file-image text-success fs-5 me-1"> </i>
                  <span class="post-text">Add Front Image of the Document</span>
                </button>
              </div>
              <div v-if="errorMessage" class="text-danger">
                {{ errorMessage }}
              </div>
            </div>
            <div class="modal-footer border-0 justify-content-center py-3">
              <button
                type="button"
                class="btn cancel-btn px-5 py-2"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="submit"
                class="btn submit-btn px-5 py-2 ms-1 ms-lg-3"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex"
import { getCloudinaryPId } from "@/utils/misc"

export default {
  name: "PostLostId",
  props: {
    lostDoc: Object,
    method: {
      type: String,
      default: "create"
    },
    user: Object
  },
  data() {
    return {
      imageUrl: null,
      errorMessage: null,
      lostObj: null
    }
  },
  created() {
    this.lostObj = {
      status: this.lostDoc.status,
      doc_type: this.lostDoc.doc_type,
      image_path: this.lostDoc.image_path,
      surname: this.lostDoc.surname,
      given_name: this.lostDoc.given_name,
      dob: this.lostDoc.dob,
      profession: this.lostDoc.profession,
      description: this.lostDoc.description,
      unique_identification_number: this.lostDoc.unique_identification_number,
      place_of_pick: this.lostDoc.place_of_pick
    }
    this.addUserData()
  },
  mounted() {
    let modal = this.$refs.postLostDocument
    modal.addEventListener("hidden.bs.modal", () => {
      this.closeModal()
    })
    modal.addEventListener("shown.bs.modal", () => {
      let textarea = this.$refs.lostDocumentDescription
      textarea.style.height = `${textarea.scrollHeight}px`
    })
  },
  methods: {
    ...mapActions("lostDocument", ["createLostDocument", "editLostDocument"]),
    closeModal() {
      this.imageUrl = null
      this.errorMessage = null
      this.$refs.image.value = ""
      this.$emit("reset-default")
    },
    handleFile(e) {
      let file = e.target.files[0]
      this.lostObj.image_path = file
      this.imageUrl = URL.createObjectURL(file)
      this.errorMessage = null
    },
    submitLostDoc() {
      this.lostObj.status = "found"
      this.lostObj.doc_type = "Identity Card"
      if (!this.lostObj.image_path) {
        this.errorMessage = "You need to add an image of the document"
        return
      }

      let formData = new FormData()
      for (const property in this.lostObj) {
        formData.append(property, this.lostObj[property])
      }

      if (this.method == "create") {
        this.createLostDocument(formData)
        this.$refs.closeButton.click()
      } else {
        formData.append("id", this.lostObj.id)
        this.editLostDocument(formData)
        this.$refs.closeButton.click()
      }
    },
    clearImg() {
      this.imageUrl = null
      this.lostObj.image_path = null
      this.$refs.image.value = ""
    },
    resize(event) {
      event.target.style.height = "auto"
      event.target.style.height = `${event.target.scrollHeight}px`
    },
    addUserData() {
      let userData = {
        email: this.user.email,
        phone_number: this.user.phone_number,
        country: this.user.country,
        city: this.user.city
      }
      this.lostObj = { ...this.lostObj, ...userData }
    },
    getCloudinaryPId: getCloudinaryPId
  },
  watch: {
    lostDoc(data) {
      this.lostObj = data
      this.addUserData()
    }
  }
}
</script>

<style scoped>
.form-control {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 28px;
  color: #6d6d6d;
}
.file-input {
  position: relative;
  opacity: 0.1;
  z-index: 3;
}
.file-input-placeholder {
  margin-top: -2.2rem;
  z-index: 1;
  /* position: absolute; */
}
.file-input-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 28px;
  color: #6d6d6d;
  z-index: 2;
}
.form-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;

  text-align: center;

  color: #000000;
}
.image-previewBlock {
  display: block;
  cursor: pointer;
  /* width: 300px; */
  height: 280px;
  background-position: center center;
  background-size: cover;
}
.cancel-btn {
  border: 1px solid #979797;
  box-sizing: border-box;
  border-radius: 52px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  color: #000000;
}
.submit-btn {
  background: linear-gradient(180deg, #ff411f 0%, #ff0bb9 100%);
  border-radius: 52px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  color: #ffffff;
}

.close-file-btn {
  top: 30px;
  right: 9px;
  cursor: pointer;
  color: #ffc107;
}

.close-file-btn:hover {
  color: #adb5bd;
}
</style>
