<template>
  <document-delete-modal :lostDocId="document.id"></document-delete-modal>
  <post-lost-id
    :lostDoc="document"
    :method="lostDocMethod"
    :user="user"
    :ref="'editDocumentModal' + document.id"
  ></post-lost-id>
  <div
    class="pb-4"
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true
    }"
  >
    <div class="d-flex align-items-center justify-content-between mb-4">
      <div class="">
        <p class="document-type">Lost {{ document.doc_type }}</p>
        <span class="views-and-date">
          <i class="bi bi-eye-fill me-1"></i> {{ document.view_count }} views
          <span class="ms-2">
            {{ moment(document.created_at).format("MMMM Do YYYY, h:mm:ss a") }}
          </span></span
        >
      </div>

      <div class="dropdown" v-if="user.id === document.user.id">
        <button
          class="btn px-1 pb-1"
          type="button"
          id="postActions"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i class="bi bi-three-dots-vertical" style="color: #6d6d6d"></i>
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <li>
            <button
              class="dropdown-item"
              @click="openDocumentModal(document.id)"
            >
              Edit Lost Document
            </button>
          </li>
          <li>
            <button
              class="dropdown-item"
              data-bs-toggle="modal"
              :data-bs-target="'#documentDeleteModal' + document.id"
            >
              Move to trash
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div class="text-start">
      <div class="row">
        <p class="col-12 col-md-6">
          <span class="field-label">Name:</span>
          <span class="field-value ms-2">
            {{ document.surname }} {{ document.given_name }}
          </span>
        </p>
        <p class="col-12 col-md-6">
          <span class="field-label">Date of birth:</span>
          <span class="field-value ms-2">{{ document.dob }}</span>
        </p>
      </div>
      <div class="row">
        <p class="col-12 col-md-6">
          <span class="field-label">Profession:</span>
          <span class="field-value ms-2">
            {{ document.profession }}
          </span>
        </p>
        <p class="col-12 col-md-6">
          <span class="field-label">UIN:</span>
          <span class="field-value ms-2">
            {{ document.unique_identification_number }}
          </span>
        </p>
      </div>
      <div class="row">
        <p class="col-12 col-md-6">
          <span class="field-label">Place of pick:</span>
          <span class="field-value ms-2">
            {{ document.place_of_pick }}
          </span>
        </p>
      </div>
    </div>
    <div class="d-flex py-2" v-if="document.description">
      <p v-if="seeMore" class="text-start">
        <span class="field-label">Note:</span>
        <span class="field-value ms-2">
          {{ document.description }}
        </span>
      </p>
      <button
        type="button"
        class="btn btn-link shadow-none ms-2"
        style="text-decoration: none"
        @click="seeMore = !seeMore"
      >
        <span v-if="seeMore"
          >See less <i class="bi bi-chevron-compact-up"></i
        ></span>
        <span v-else>See more <i class="bi bi-chevron-compact-down"></i></span>
      </button>
    </div>
    <div class="row">
      <img :src="document.image_path" class="" />
    </div>
    <div class="row mt-4 border-top border-2">
      <div class="d-flex align-items-center justify-content-between py-1">
        <!-- :href="'https://wa.me/+237' + document.user.phone_number" -->
        <a
          :href="`https://wa.me/+237${document.user.phone_number}`"
          class=""
          target="_blank"
        >
          <i class="bi bi-whatsapp fs-1 text-success"></i>
        </a>
        <button class="btn" style="color: #6d6d6d">
          <i class="bi bi-share fs-4"></i>
          <span class="ms-2"> Share</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex"
import moment from "moment"
import { Modal } from "bootstrap"
import DocumentDeleteModal from "@/components/lostDocument/DocumentDeleteModal.vue"
import PostLostId from "@/components/lostDocument/PostLostId.vue"
export default {
  name: "DocumentDetail",
  props: { user: Object, document: Object },
  components: { DocumentDeleteModal, PostLostId },
  data() {
    return {
      lostDocMethod: "edit",
      seeMore: false
    }
  },
  created() {
    this.moment = moment
  },

  methods: {
    ...mapActions("lostDocument", ["viewLostDocument"]),
    openDocumentModal(docId) {
      const modal = new Modal(
        this.$refs[`editDocumentModal${docId}`].$refs.postLostDocument
      )
      modal.show()
    },
    visibilityChanged(isVisible) {
      if (isVisible) {
        this.viewLostDocument({ id: this.document.id })
      }
    }
  }
}
</script>

<style scoped>
.recommended-users {
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.document-type {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 36px;
  display: flex;
  align-items: center;

  color: #000000;
}
.views-and-date {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  margin-top: -1.3rem;

  color: #c4c4c4;
}
.field-label {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;

  color: #000000;
}
.field-value {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  /* identical to box height */

  color: #000000;
}
</style>
