<template>
  <div class="p-0">
    <div
      class="bg-white rounded-3 shadow-sm p-3 mt-3 h-auto"
      v-for="document in lost_documents"
      :key="document.id"
    >
      <document-detail :user="user" :document="document"></document-detail>
    </div>
    <div>
      <button
        type="button"
        class="btn btn-outline-primary my-4"
        @click="loadNextPage"
        v-if="page.last_page > page.current_page"
      >
        See more documents
        <i class="bi bi-arrow-up-right-square-fill"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import DocumentDetail from "@/components/lostDocument/DocumentDetail.vue"

export default {
  name: "DocumentNewsFeed",
  props: { user: Object },
  components: { DocumentDetail },
  mounted() {
    this.getLostDocuments({ page: 1 })
  },
  computed: {
    ...mapState("lostDocument", ["lost_documents", "page"])
  },
  methods: {
    ...mapActions("lostDocument", ["getLostDocuments"]),
    loadNextPage() {
      this.getLostDocuments({ page: this.page.current_page + 1 })
    }
  }
}
</script>
